<template>
  <div class="account-table">
    <el-table
      border
      :data="copyList"
      style="width: 100%"
      :row-style="{ height: 0 }"
      :cell-style="{ padding: '8px 0' }"
    >
      <!-- <el-table-column prop="tempId" label="ID" align="center"></el-table-column> -->
      <!-- <el-table-column prop="nikename" label="客服昵称" align="center"></el-table-column> -->
      <el-table-column prop="username" label="账号" align="center" fixed="left"></el-table-column>
      <!--
      <el-table-column label="密码" align="center">
        <template slot-scope="scope">
          <div class="psd-cell" @click="checkLock(scope.$index, scope.row)">
            <span>{{ scope.row.showPsd ? scope.row.password : '************' }}</span>
            <i :class="scope.row.showPsd ? 'el-icon-unlock' : 'el-icon-lock'"></i>
          </div>
        </template>
      </el-table-column>
      -->
      <!-- <el-table-column prop="mobile" label="手机号" align="center"></el-table-column>
      <el-table-column prop="wechat" label="微信号码" align="center"></el-table-column> -->
      <!-- <el-table-column prop label="微信二维码" align="center">
        <template slot-scope="scope">
         <img
           :src="base+'api/common/attach/' + scope.row.wechatQrcode "
           height="40"
           width="60"
           alt
           srcset
         />
          <el-image
            :src="base+'api/common/attach/' + scope.row.wechatQrcode "
            style="width: 60px; height: 40px"
            :preview-src-list="[base+'api/common/attach/' + scope.row.wechatQrcode]"
            alt
          >
            <div slot="error">
            </div>
          </el-image>
        </template>
      </el-table-column> -->

      <!-- <el-table-column prop="qq" label="QQ号码" align="center"></el-table-column> -->

      <el-table-column prop="roleName" label="角色组" align="center"></el-table-column>
      <el-table-column label="当前状态" align="center">
        <template slot-scope="scope">
          <el-tag
            size="mini"
            effect="dark"
            :type="scope.row.status ? 'danger' : 'success'"
          >{{ scope.row.status ? '禁用' : '正常' }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" align="center" width="120" fixed="right">
        <template slot-scope="scope">
          <el-button
            type="success"
            size="mini"
            icon="el-icon-edit"
            circle
            @click="$emit('show-edit', scope.row )"
          ></el-button>
          <el-button
            type="danger"
            size="mini"
            icon="el-icon-delete"
            circle
            @click="$emit('delete', scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { base, baseUrl } from '@/api/env';
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      base: ''
    };
  },
  computed: {
    copyList() {
      return this.list;
      /*
      let copy = JSON.parse(JSON.stringify(this.list));
      copy.forEach(item => {
        item.showPsd = false;
      });
      return copy;
       */
    }
  },
  methods: {
    /*
    checkLock(index, row) {
      row.showPsd = !row.showPsd;
      this.copyList.splice(index, 1, row);
    }
     */
  },
  mounted() {
    this.base = base;
  }
};
</script>

<style lang="scss" scoped>
.account-table {
  .psd-cell {
    width: 100%;
    position: relative;
    i {
      position: absolute;
      right: 0;
    }
  }
}
</style>
